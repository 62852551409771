

import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "private-chat",
  setup() {
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      if(route.params.id){
        if(route.params.folder){ 
          console.log(route.params.folder);
          router.push(`/user/inbox/${route.params.id}/${route.params.folder}`)
        }
      }
        return {
        };
    });
  },
});
